import React from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import { Link } from "gatsby";
import DropdownMenu from "../components/DropdownMenu";
import { ACTIVE_PAGE } from "../components/nav/utils";
import { useEffect, useState } from "react";
import {
  BlogHeaderImage,
  BlogHeaderVideo,
  CardTagButton,
} from "../page-components/blog/styles";
import { isMp4 } from "../utils/functions";
import ShareButtons from "../components/sharebuttons";
import ContactRequestContainer from "../page-components/blog/ContactRequestContainer/ContactRequestContainer";
import { twoDLinks } from "./2D";
import { kebabCase } from "lodash";
import {
  Content,
  ContentWrapper,
  Divider,
  HeaderContainer,
  PostHeader,
} from "../page-components/blog/BlogPost.styles";

interface MediaComponentProps {
  mediaSrc: string;
  imageFocus?: string;
}

const MediaComponent: React.FC<MediaComponentProps> = ({
  mediaSrc,
  imageFocus = "",
}) => {
  if (!mediaSrc) return null;

  return isMp4(mediaSrc) ? (
    <BlogHeaderVideo loop autoPlay muted style={{ objectPosition: imageFocus }}>
      <source src={mediaSrc} type="video/mp4" />
    </BlogHeaderVideo>
  ) : (
    <BlogHeaderImage
      src={mediaSrc}
      alt=""
      style={{ objectPosition: imageFocus }}
    />
  );
};

const getIndex = (searchQuery: string) => {
  const urlQuery = /^(?:\?view=)(.*)/.exec(searchQuery);
  const query = urlQuery ? urlQuery[1].replaceAll("+", " ") : null;
  if (!query) return 0;

  const titles = twoDLinks.map((link) => link.title);
  if (titles.includes(query))
    return titles.findIndex((title) => title === query);
  else return 0;
};

export default function TwoDPage() {
  const [linkIndex, setLinkIndex] = useState<number | null>(null);
  const [url, setUrl] = useState("");
  const tags = ["As-Built Documentation", "Adaptive Reuse", "Scan-to-BIM"];

  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setLinkIndex(getIndex(window.location.search));
    }
  }, []);

  const handleClick = (title: string, index: number) => {
    if (typeof window !== "undefined") {
      const url = new URL(window.location.href);
      url.searchParams.set("view", title);
      window.history.pushState({}, "", url.toString());
      setLinkIndex(index);
    }
  };

  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.TWODBLOG}>
      <Seo
        title="2D CAD Drawing Services | Robotic Imaging"
        description="Sample 2D Imaging services provided by Robotic Imaging."
      />
      <div style={{ padding: "60px 0 0 0" }}></div>
      <Divider />

      <HeaderContainer>
        <PostHeader>Standard 2D CAD Delivery</PostHeader>
      </HeaderContainer>

      <ContentWrapper>
        <Content>
          <MediaComponent mediaSrc="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-videos%2F2D%2F178ContinentalRd_SectionAnimation%20(1).mp4?alt=media&token=589fef82-9299-4374-b710-c151fd5b0a5d" />
          <br />
          <small>
            {tags.map((tag, i) => (
              <CardTagButton key={tag}>
                <small>
                  <Link
                    className="blog-post__tag-link"
                    to={`/tags/${kebabCase(tag)}/`}
                  >
                    {tag}
                  </Link>
                  {i < tags.length - 1 ? "" : ""}
                </small>
              </CardTagButton>
            ))}
          </small>
          <small className="small_text">Robotic Imaging</small>
          <small className="small_text">March 26, 2024</small>

          <small style={{ paddingTop: "10px" }}>
            <ShareButtons
              title={"Standard 2D CAD Delivery"}
              url={url}
              tags={tags}
            />
          </small>
          <br />
          <div className="site__header__button-container-tags">
            {twoDLinks.map((link, index) => (
              <button
                key={link.title}
                onClick={() => handleClick(link.title, index)}
                disabled={linkIndex === index}
                className={`site__header__button${
                  linkIndex === index ? "-disabled" : ""
                }`}
              >
                {link.title}
              </button>
            ))}
          </div>

          <DropdownMenu
            index={linkIndex}
            setIndex={setLinkIndex}
            options={twoDLinks}
          />

          <iframe
            className="deliverable__iframe"
            title="two-d-drawing"
            src={linkIndex !== null ? twoDLinks[linkIndex].url : ""}
            frameBorder="0"
            allowFullScreen
          />
          <div className={"blog-post__html"}>
            <h2>Transforming Reality into 2D CAD</h2>
            <p>
              Scan-to-2D CAD services are a fundamental aspect of the
              architecture, engineering, and construction (AEC) industry. This
              process involves converting 3D scans of physical objects or spaces
              into accurate and detailed 2D computer-aided design (CAD)
              drawings.
            </p>
            <p>
              Starting with a 3D scan utilizing specialized devices like laser
              scanners, we create 2D drawings by tracing over the point cloud
              data to outline the features of the object or space.
            </p>
            <p>
              These 2D CAD drawings have diverse applications, from
              architectural and mechanical design to structural analysis and
              construction documentation. Scan-to-2D CAD services excel at
              capturing complex or irregular shapes that are challenging to
              manually measure and draw.
            </p>
          </div>
          <video
            className="video"
            src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-videos%2F2D%2F178ContinentalRd_SectionAnimation%20(1).mp4?alt=media&token=589fef82-9299-4374-b710-c151fd5b0a5d"
            autoPlay
            loop
            muted
            playsInline
            style={{
              maxWidth: "1050px",
            }}
          />
        </Content>
      </ContentWrapper>
      <Divider />
      <Content>
        <div
          className="page-navigation"
          style={{ flexDirection: "column", borderBottom: "none" }}
        >
          <ContactRequestContainer />
        </div>
      </Content>
    </SiteWrapper>
  );
}
